import React from 'react';
import PTRUI from 'protrans-react-ui';
import AppConfig from './app-config/app-config';
import AppStartUp from './app-startup';
import AppAlerts from './app-alerts';
import AppNavigation from './app-navigation';
import Routes from './routes';
import { appStore } from './Store';
import './styles.global.scss';

function App() {
    return (
        <PTRUI.App
            config={AppConfig}
            startup={AppStartUp}
            navigation={AppNavigation}
            alerts={AppAlerts}
            routes={Routes}
            store={appStore}
            basename={'/pud'}
        />
    );
}

export default App;