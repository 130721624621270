
/*
    Can be used to run scripts on App Load
*/
import React from 'react';
import PTRUI from 'protrans-react-ui';

export default function AppStartUp() {
    const {token} = PTRUI.useLogin();
    const {setBaseHeaders} = PTRUI.useConfig();

    React.useEffect(() => {
        setBaseHeaders({ 'Content-Type': "application/json", 'Authorization': `Bearer ${token}` })
    }, [token])

    return null;
}