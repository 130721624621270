
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

//via: https://mariusschulz.com/blog/declaring-global-variables-in-typescript
declare global {
  interface Window {
    gbl_React_App_Service_URL: any;
    gbl_Optimiz_App_URL: any;
    gbl_React_App_Documents_Path: any;
  }
}

ReactDOM.render(<App />, document.getElementById('root'));

