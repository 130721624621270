import React from 'react';
import PTRUI, { Config } from 'protrans-react-ui';
import LoginForm, { FormTitle } from './login-form';
import * as locale from './locale/i18n.json';

export default function AppConfig() {
    const { token, clearToken, clearUser, setIsAuth } = PTRUI.useLogin();
    const baseUrl = window.gbl_React_App_Service_URL;
    return (
        <Config
            alerts={false}
            search={false}
            externali18nKeys = {locale}
            mainMenu={false}
            appLogo={'protrans'}
            appVersion={'2.0.0'}
            apiBaseURLTypes={{
                default: baseUrl + "api/" || '',
               someApi: ''
            }}
            apiBaseHeaders={{
                'Content-Type': "application/json", 'Authorization': `Bearer ${token}`
            }}
            appLogin={{
                form: LoginForm,
                formTitle: () => <FormTitle />,
                altUrlLogin : '/auth'
            }}
            appLogout={{
                confirmAction: (() => {
                    setIsAuth(false);
                    clearUser();
                    clearToken();
                })
            }}
            languageSwitcher={true}
            apiFetchCredentials={{
                default: 'omit'
            }}
        />
    )
}