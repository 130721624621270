import React from "react";
import PTRUI from 'protrans-react-ui';
import { Route } from "react-router-treble";

const Deliveries = PTRUI.lazyImport(() => import('./scenes/deliveries'));
const Pickups = PTRUI.lazyImport(() => import('./scenes/pickups'));
const Drivers = PTRUI.lazyImport(() => import('./scenes/drivers'));
const Zones = PTRUI.lazyImport(() => import('./scenes/zones'));
const MapView = PTRUI.lazyImport(() => import('./scenes/map-view'));
const Home = PTRUI.lazyImport(() => import('./scenes/home'));
const Hours = PTRUI.lazyImport(() => import('./scenes/hours'));
const Trailers = PTRUI.lazyImport(() => import('./scenes/trailers'));
const AlternativeLoginPage = PTRUI.lazyImport(() => import("../components/app-config/login-form/alternative-login-page"));

function Routes() {
    return (
        <>
            {/* Home */}
            <Route exact path='/'>
                <Home />
            </Route>

             {/* Alternative Login Page */}
             <Route exact path='/auth'>
                <AlternativeLoginPage />
            </Route>

            {/* Deliveries */}
            <Route exact path='/deliveries'>
                <Deliveries />
            </Route>

            {/* Pickups */}
            <Route exact path={'/pickups'}>
                <Pickups />
            </Route>

            {/* Drivers */}
            <Route exact path={'/drivers'}>
                <Drivers />
            </Route>

            {/* Zones */}
            <Route exact path={'/zones'}>
                <Zones />
            </Route>

            {/* Map View */}
            <Route exact path={'/map-view'}>
                <MapView />
            </Route>
            {/* Hours */}
            <Route exact path={'/hours'}>
                <Hours />
            </Route>

            {/* Trailers */}
            <Route exact path={'/trailers'}>
                <Trailers />
            </Route>
        </>
    );
}

export default Routes;