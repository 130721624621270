import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Button, Container } from 'react-bootstrap';
import { useTreble } from 'treble-gsm';
import md5 from 'md5';
import { createInAppMessage, clearAllInAppMessages } from 'protrans-react-ui';
import PTRUI from 'protrans-react-ui';
import { useFetchPTR } from '../../app-services';
import { useAppStore } from "../../Store";
import { getNotificationCounts } from '../../scenes/_services';
import { Redirect, useHistory } from 'react-router-treble';

export default function LoginPage() {
    const history = useHistory();
    const { setUser, setToken, clearToken, clearUser, setIsAuth, isAuth } = PTRUI.useLogin();
    const [{ hubInformation, driverNotificationCount, deliveryNotificationCount, pickupNotificationCount, urlPath }, appStore] = useAppStore();
    const [userName, setUserName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [triggerValidate, setTriggerValidate] = React.useState<boolean>(true);
    const message = PTRUI.useInAppMessage();


    const { response: loginUserResponse, error: loginUserError, loading: loginUserLoading } = useFetchPTR(
        `api/account/validate`,
        {
            headers: { UserName: userName, Password: md5(password) },
            trigger: [triggerValidate],
            method: "POST",
            initialMount: false
        }
    );

    //Set Login data
    PTRUI.useNonInitialEffect(() => {
        if (!loginUserLoading) {
            let response = (loginUserResponse.data as any);

            if (response?.StatusCode === 401) {
                message.createError("Invalid UserName or Password");
            }
            else if (!response.Result) {
                getNotificationCounts(Number(response?.HubId), { setIsAuth, clearToken, clearUser })
                    .then((data: any) => {
                        appStore.update("updateDriverCount", { ...driverNotificationCount, driverCount: data?.driverCount });
                        appStore.update("updateDeliveriesCount", { ...deliveryNotificationCount, deliveriesCount: data?.deliveriesCount });
                        appStore.update("updatePickupsCount", { ...pickupNotificationCount, pickupsCount: data?.pickupsCount });
                    });

                setIsAuth(true);
                setUser({
                    id: response?.DriverId,
                    firstName: response?.FirstName,
                    lastName: response?.LastName,
                    userName: response?.UserName,
                    email: response?.EmailAddtess,
                    profilePic: ''
                });
                setToken(response?.Token);
                sessionStorage.setItem("ptrui_userToken", response?.Token);
                appStore.update("updateHubInformation", { ...hubInformation, HubId: Number(response?.HubId), HubName: response?.HubName, Longitude: response?.Longitude, Latitude: response?.Latitude });
                history.push("/");
            }
            else
                message.createError("Error occurred while connecting to API service.");
        }
    }, [loginUserLoading]);

    useEffect(() => {
        if (urlPath.pathName == '/auth') {
            history.push("/");
        }
        else if (isAuth) {
            history.push(urlPath.pathName);
        }
    }, [isAuth])


    const validationHandler = (): boolean => {
        let error = "";
        let isValid = true;

        if (userName === "" && password === "")
            error = "User name/ Password are required to login.";
        else if (userName === "")
            error = "User name is required to login.";
        else if (password === "")
            error = "Password is required to login.";

        if (error != "") {
            message.createError(error, { dismiss: true });
            isValid = false;
        }

        return isValid;
    }

    const loginClickHandler = () => {
        if (validationHandler()) {
            setTriggerValidate(!triggerValidate);
        }
    }
    const enterKeyPressed = (event: any) => {
        if (event.which == 13) {
            loginClickHandler();
        }
    }

    return (
        <>
            {
                isAuth ?
                    <Redirect to={"/"} /> :
                    <Form className='pt-3'>
                        <Form.Group controlId="loginPageUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="username" placeholder="Enter Username" value={userName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserName(e.target.value)}
                                onKeyPress={(e: any) => enterKeyPressed(e)} />
                        </Form.Group>
                        <Form.Group controlId="loginPagePassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter Password" value={password}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                                onKeyPress={(e: any) => enterKeyPressed(e)} />
                        </Form.Group>
                        <div className={'d-flex justify-content-center py-3'}>
                            <Button variant="primary" className={'px-3'} onClick={() => { loginClickHandler() }}>
                                Sign In
                            </Button>
                        </div>
                    </Form>
            }
        </>
    )
}