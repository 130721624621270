import React from 'react';
import {AlertMenu} from 'protrans-react-ui';


function AppAlerts(){
    const alertCards: any[] = [
        {
            alertID: 1234,
            shipmentID: 3456,
            type: 'warning',
            message: 'The foo is the foo in your heart.  Need to fix that.'
        },
        {
            alertID: 1235,
            shipmentID: 3457,
            type: 'warning',
            message: 'So much foo in that heart. Please fix the foo.'
        }
    ];
    return(
        <>
            <AlertMenu dataSet={alertCards}/>
        </>
    )
}
export default AppAlerts;