import { createStore, useTreble } from 'treble-gsm';
import { TAppStoreHook } from './interfaces';

const useAppStore = () => useTreble() as TAppStoreHook;

const appStore = createStore(
    [
        {
            action: 'updateHubInformation',
            state: {
                hubInformation: {
                    HubId: 0,
                    HubName: "",
                    Longitude: "",
                    Latitude: ""
                }
            },
            features: {
                persist: true
            }
        },
        {
            action: 'updateDriverCount',
            state: {
                driverNotificationCount: {
                    driverCount: 0
                }
            },
            features: {
                persist: true
            }
        },
        {
            action: 'updateDeliveriesCount',
            state: {
                deliveryNotificationCount: {
                    deliveriesCount: 0
                }
            },
            features: {
                persist: true
            }
        },
        {
            action: 'updatePickupsCount',
            state: {
                pickupNotificationCount: {
                    pickupsCount: 0
                }
            },
            features: {
                persist: true
            }
        },
        {
            action: 'autoLogoutTimer',
            state: {
                idleTime: {
                    maxIdleTime:200,
                }
            },
            features: {
                persist: true
            }
        },
        {
            action: 'updatePath',
            state: {
                urlPath: {
                    pathName: ""
                }
            },
            features: {
                persist: true
            }
        }
    ]
);

export { useAppStore };
export default appStore;
